import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NextImage, RichText, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect } from 'react';
import { setContext } from '../../lib/utils/coveo/headlessContext';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';

import {
  StBannerContainer,
  StBannerContent,
  StBannerWrapper,
  StRichTextGridItem,
} from './HeroAndSearch.styles';
import SearchForm from './SearchForm';
import { HeroAndSearchType } from './HeroAndSearch.types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setDayOfBirth } from '../../features/coveoSlice';

const HeroAndSearch = (props: HeroAndSearchType): JSX.Element => {
  const theme = useTheme();
  const { sitecoreContext }: any = useSitecoreContext();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const userProfile = useSelector((state: RootState) => state.crProfile);
  const profileDetails = userProfile?.profileData?.basicDetails;
  const dispatch = useDispatch();
  const route = sitecoreContext?.route;
  const isFeaturedTrial =
    (route?.placeholders['jss-main'] as any)?.findIndex(
      (item: any) => item?.componentName === 'FeaturedTrial'
    ) > -1
      ? true
      : false;

  useEffect(() => {
    if (isEditorActive() || sitecoreContext?.pageState === 'preview') {
      return;
    }
    const userInterests = userProfile?.profileData?.conditions?.map(
      (item: any) => item.conditionValue
    );
    if (profileDetails) {
      setContext({
        gender: profileDetails?.gender,
        dateOfBirth: profileDetails?.dob,
        interests: userInterests ? userInterests : [],
      });
    } else {
      setContext({
        gender: '',
        dateOfBirth: '',
        interests: '',
      });
    }
  }, [profileDetails?.dob, profileDetails?.gender, userProfile?.profileData?.conditions]);

  useEffect(() => {
    if (profileDetails?.dob) {
      dispatch(setDayOfBirth(String(new Date(profileDetails?.dob).getFullYear())));
    }
  }, [dispatch, profileDetails?.dob]);

  if (!props?.fields) return <></>;

  return (
    <StBannerWrapper
      id="hero-and-search"
      className={
        isFeaturedTrial ? (isDesktop && !isEditorActive() ? 'overlap-featured-trials' : '') : ''
      }
    >
      <NextImage
        priority={true} // When true, the image will be considered high priority and preload
        field={
          isDesktop
            ? props?.fields?.desktopBGImage
            : isTablet
            ? props?.fields?.tabletBGImage
            : isMobile
            ? props?.fields?.mobileBGImage
            : props?.fields?.desktopBGImage
        }
        style={{ zIndex: 1, objectFit: 'cover', objectPosition: 'center' }}
        fill={true}
      ></NextImage>
      <StBannerContainer id="hero-and-search-content" container>
        <StBannerContent container item xs={12} sm={12} md={6} lg={6}>
          <Grid
            item
            sx={{
              [theme.breakpoints.up('sm')]: {
                maxWidth: '450px',
                margin: '0 auto',
              },
            }}
          >
            <Typography
              gutterBottom
              variant="h1"
              data-testid="hero-and-search-title"
              sx={{
                color: '#1A1A1A',
                fontSize: '1.875rem',
                marginBottom: '8px',
                lineHeight: '2.81rem',
                fontWeight: 600,
                [theme.breakpoints.down('md')]: {
                  fontSize: '2rem',
                },
                [theme.breakpoints.down('sm')]: {
                  fontSize: '1.5rem',
                  lineHeight: '2.375rem',
                },
              }}
            >
              <Text field={props?.fields?.heading}></Text>
            </Typography>
          </Grid>
          <StRichTextGridItem item>
            <RichText field={props?.fields?.content} data-testid="hero-and-search-content" />
          </StRichTextGridItem>
        </StBannerContent>

        <SearchForm {...props} />
      </StBannerContainer>
    </StBannerWrapper>
  );
};

export default HeroAndSearch;
